<template>
  <div class="container">
    <myHeader :isSenior.sync="isSenior" />
    <div class="content">
      <ul>
        <li class="item">
          <div class="top">
            <i class="i1"></i>
            <span class="title">方志</span>
            <i class="i2"></i>
          </div>
          <div class="bottom">
            <div class="border-right">
              <div class="imgBox" @click="onClick('oldRecords', 1)">
                <div class="name">县</div>
                <img class="pic" src="../../assets/image/8.jpg" />
                <p class="title">县市志</p>
              </div>
            </div>
            <div class="border-right">
              <div class="imgBox" @click="onClick('oldRecords', 0)">
                <div class="name">市</div>
                <img class="pic" src="../../assets/image/9.jpg" />
                <p class="title">省市志</p>
              </div>
            </div>
            <div class="imgBox" @click="onClick('oldRecords', 2)">
              <div class="name">乡</div>
              <img class="pic" src="../../assets/image/10.jpg" />
              <p class="title">乡镇志</p>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="top">
            <i class="i1"></i>
            <span class="title">家谱</span>
            <i class="i2"></i>
          </div>
          <div class="bottom">
            <div class="border-right">
              <div class="imgBox" @click="onClick('genealogy', '福建')">
                <div class="name">闽</div>
                <img class="pic" src="../../assets/image/5.jpg" />
                <div class="title">福建省</div>
              </div>
            </div>
            <div class="border-right">
              <div class="imgBox" @click="onClick('genealogy', '台湾')">
                <div class="name">台</div>
                <img class="pic" src="../../assets/image/4.jpg" />
                <div class="title">台湾省</div>
              </div>
            </div>
            <div class="imgBox" @click="onClick('genealogy', '其他')">
              <div class="name">其</div>
              <img class="pic" src="../../assets/image/7.jpg" />
              <div class="title">其他</div>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="top">
            <i class="i1"></i>
            <span class="title">历史文献</span>
            <i class="i2"></i>
          </div>
          <div class="bottom">
            <div class="border-right">
              <div class="imgBox" @click="onClick('historical', 135)">
                <div class="name">契</div>
                <img class="pic" src="../../assets/image/6.jpg" />
                <div class="title">契约</div>
              </div>
            </div>
            <div class="bottom">
              <div class="imgBox" @click="onClick('historical', 132)">
                <div class="name">地</div>
                <img class="pic" src="../../assets/image/7.jpg" />
                <div class="title">地名辞书</div>
              </div>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="top">
            <i class="i1"></i>
            <span class="title">报刊</span>
            <i class="i2"></i>
          </div>
          <div class="bottom">
            <div class="border-right">
              <div class="imgBox" @click="onClick('journal', 120)">
                <div class="name">报</div>
                <img class="pic" src="../../assets/image/6.jpg" />
                <div class="title">报纸</div>
              </div>
            </div>
            <div class="imgBox" @click="onClick('overseaschinese')">
              <div class="name">刊</div>
              <img class="pic" src="../../assets/image/7.jpg" />
              <div class="title">期刊</div>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="top">
            <i class="i1"></i>
            <span class="title">寻根</span>
            <i class="i2"></i>
          </div>
          <div class="bottom">
            <div class="border-right">
              <div class="imgBox" @click="friendshipClick('migrate', 120)">
                <div class="name">忆</div>
                <img class="pic" src="../../assets/image/6.jpg" />
                <div class="title">迁徙记忆</div>
              </div>
            </div>
            <div class="border-right">
              <div class="imgBox" @click="friendshipClick('drilling')">
                <div class="name">析</div>
                <img class="pic" src="../../assets/image/7.jpg" />
                <div class="title">大数据分析</div>
              </div>
            </div>
            <div class="imgBox" @click="friendshipClick('robotmigrate', 120)">
              <div class="name">台</div>
              <img class="pic" src="../../assets/image/6.jpg" />
              <div class="title">臺胞尋根智慧平臺</div>
            </div>
          </div>
          <!-- <div class="friendship">
            <span @click="friendshipClick('/migrate')">迁徙记忆</span>
            <span @click="friendshipClick('/drilling')">大数据分析</span>
            <span @click="friendshipClick('/robotmigrate')">台胞智能寻根网</span>
          </div> -->
        </li>
      </ul>
    </div>
    <div class="mobile">
      <vue-qr
        text="https://xungen.guanshutangocr.com/#/robotmigrate"
        :size="300"
        :logoScale="0.2"
      >
      </vue-qr>
      <span>手机端访问</span>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import myHeader from "@/components/header/index.vue";
export default {
  data() {
    return {
      isSenior: false,
    };
  },
  components: {
    myHeader,
    VueQr,
  },
  methods: {
    onClick(name, type) {
      let routeData = this.$router.resolve({
        path: "/" + name,
        query: {
          type,
        },
      });
      window.open(routeData.href, "_blank");
    },
    friendshipClick(path) {
      let routeData = this.$router.resolve({
        path,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  background: #f7f4ef;
  position: relative;
  .content {
    position: relative;
    background: #fff;

    height: 100%;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .friendship {
        span {
          display: inline;
          padding: 20px;
          cursor: pointer;
          color: blue;
          text-decoration: underline;
        }
        span:hover {
          color: red;
        }
      }
      .top {
        display: flex;
        align-items: center;
        margin-top: 30px;
        padding: 20px 0 22px;
        line-height: 44px;

        .i1 {
          display: inline-block;
          width: 405px;
          height: 44px;
          background: url("../../assets/image/left.png") right center no-repeat;
        }
        .title {
          display: inline-block;
          width: 200px;
          font-size: 24px;
          text-align: center;
          line-height: 103px;
          color: #333;
        }
        .i2 {
          display: inline-block;
          width: 405px;
          height: 44px;
          background: url("../../assets//image/right.png") left center no-repeat;
        }
      }
      .bottom {
        display: flex;
        margin-top: 10px;
      }
      .border-right {
        width: 350px;
        height: 455px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 30px 0;
        border-right: 1px solid #000;
      }
      .imgBox {
        width: 350px;
        height: 455px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 30px 0;
        border-right: 1px solid #000;
        cursor: pointer;
        .name {
          position: absolute;
          top: -27px;
          width: 79px;
          height: 79px;
          border-radius: 50%;
          text-align: center;
          background: url("../../assets/image/base.png");
          font-size: 44px;
          line-height: 79px;
          color: #f0f0f0;
        }
        .pic {
          width: 230px;
          height: 430px;
        }
        .title {
          display: inline-block;
          position: absolute;
          width: 50px;
          min-height: 200px;
          line-height: 46px;
          font-size: 36px;
          color: #fff;
          text-align: center;
        }
      }
      .imgBox:last-child {
        border: none;
      }
      .imgBox:hover {
        transform: translate(0, -10px);
        transition: all 1s;
      }
    }
  }
  .mobile {
    position: fixed;
    right: 5px;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
  }
  .mobile:hover {
    border: 1px solid #000;
    border-radius: 5px;
  }
}
</style>